.chip {
  --chip-size: #{rem($button-base-size)};
  --chip-font-size: 1rem;
  --chip-color: white;
  --chip-bg: var(--bs-primary);

  min-width: var(--chip-size);
  width: var(--chip-size);
  height: var(--chip-size);
  aspect-ratio: 1/1;
  border-radius: rem(200px);
  background-color: var(--chip-bg);

  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: var(--chip-font-size);
  color: var(--chip-color);

  fstr-icon {
    z-index: 2;
  }

  .icon {
    @include size(var(--chip-font-size));
    margin: auto;
  }
}

@each $name, $value in $button-sizes {
  .chip--#{$name} {
    --chip-size: #{rem($value)};
    --chip-font-size: #{map-get($font-sizes, 'body-' + $name)};
  }
}

@each $name, $color in $theme-colors {
  .chip--#{$name} {
    --chip-bg: #{$color};
    --chip-color: #{get-contrast-color($color)};
  }
}

@each $name, $color in $button-themes {
  .chip--#{$name} {
    --chip-bg: #{map-get($color, 'bg')};
    --chip-color: #{map-get($color, 'color')};
  }
}
.chip-outline {
  border: solid 1px var(--chip-outline-color);
  --chip-color: var(--chip-outline-color);
  --chip-outline-color: var(--bs-primary);
  background-color: transparent;
  color: var(--chip-color);
  &.chip--light {
    --chip-outline-color: var(--bs-light);
    --chip-color: var(--bs-light);
  }
}

.chip.chip--primary-subtle {
  --chip-color: var(--bs-primary);
}

.chip.chip--secondary-subtle {
  --chip-color: var(--bs-secondary);
}
