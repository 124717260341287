.fr-video {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 56.25%;
  margin-bottom: 3ex;

  @include media-breakpoint-up(lg) {
    margin-left: calc(var(--content-media-outset) * -1);
    margin-right: calc(var(--content-media-outset) * -1);
    width: calc(100% + var(--content-media-outset) * 2);
    max-width: none;
    padding-top: calc(56.25% + var(--content-media-outset) * 1);
  }

  iframe {
    @include abs(0, 0, 0, 0);
    @include size(100%);
    border-radius: var(--media-border-radius, var(--bs-border-radius));
    overflow: hidden;
  }
}

iframe,
object,
embed {
  width: 100%;
  height: auto;
}
