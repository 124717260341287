body {
  --content-media-outset: 0px;

  @include media-breakpoint-up(lg) {
    --content-media-outset: 100px;
  }

  @include media-breakpoint-up(xxl) {
    --content-media-outset: 150px;
  }
}

.markdown-content {
  $markdown-font-sizes: (
    h1: 36px,
    h2: 30px,
    h3: 26px,
    h4: 22px,
    h5: 18px,
    h6: 18px,
  );

  font-family: var(--bs-body-font-family);
  line-height: 1.6;

  @import './blockquote';
  @import './image';
  @import './table';
  @import './type';
  @import './media';
  @import './content-slider';
  @import './alert';

  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }
}

@import './simple-markdown';
@import './embed-removed';
@import './stripped-markdown';
