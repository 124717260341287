html {
  --mat-dialog-container-small-max-width: calc(100vw - 20px);
}

.cdk-overlay-pane.mat-mdc-dialog-panel {
  width: 100%;
}
.mat-mdc-dialog-surface {
  border-radius: $border-radius-sm !important;
  @extend .custom-scrollbar;
}

//  add disable-scroll as panelClass to disable scrolling

//  add disable-scroll as panelClass to disable scrolling
.custom-background-and-scroll .mat-mdc-dialog-surface.mdc-dialog__surface {
  box-shadow: none;
  background: transparent;
  overflow: hidden;
  border-radius: 0 !important;
  justify-content: center;
}
